.court-container {
  width: 100%;
  margin: auto;
  position: relative;
}

.court {
  width: 100%;
  height: auto;
}

.highlight-rect {
  fill: rgba(255, 255, 0, 0); /* Yellow with 50% opacity */
}

.highlight-rect.active {
  fill: rgba(255, 255, 0, 0.7); /* Fully opaque yellow */
  stroke: none;
  transition: fill 0.5s ease;
}
